<template>
  <div class="field" v-if="data">
    <label class="label">Callback</label>
      <table class="table transparent-bg" style="width: auto;">
        <tbody v-for="callback in data" v-bind:key="callback.callback_id">
          <template v-if="callback.callback_name">
            <tr>
              <td><b>{{ callback.callback_name }}</b></td>
              <td>{{ callback.datetime }}</td>
              <td> Status {{ callback.response.status_code }}</td></tr>
          </template>
          <template v-else>
            <tr v-for="(response, i) in callback.response" v-bind:key="response._id">
              <td><b v-if="i ===0">{{ callback.name }}</b></td>
              <td>{{response.created_at }}</td>
              <td> Status {{response.code || response.error || 'ERROR'}}</td>
              <td>
                <b-button size="is-small" v-on:click="showDetail(callback, response)">
                  <b-icon icon="eye" size="is-small"/>
                </b-button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

  <b-modal :active.sync="isImageModalActive" has-modal-card >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="detail"> Callback {{ detail.callback.name }}</p>
        <button class="delete" aria-label="close" v-on:click="isImageModalActive = false"></button>
      </header>
      <section class="modal-card-body" v-if="detail">
        <strong>Request:</strong>
        <vue-json-pretty :data="detail.callback.request"></vue-json-pretty>
        <strong>Response:</strong>
        <vue-json-pretty :data="detail.response"></vue-json-pretty>
      </section>
    </div>
  </b-modal>

    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'CallbackDetail',
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isImageModalActive: false,
      detail: null,
    };
  },
  components: { VueJsonPretty },
  methods: {
    showDetail(callback, response) {
      this.detail = { callback, response };
      this.isImageModalActive = true;
    },
  },
};
</script>

<style>
.field {
  position: relative;
}
.transparent-bg {
  background-color: transparent !important;
}
</style>
