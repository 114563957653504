<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        {{ message.id }}
      </div>
    </div>
    <div class="field">
      <label class="label">Created at</label>
      <div class="control">
        {{ message.created_at | moment('YYYY.MM.DD H:mm:ss') }}
      </div>
    </div>
    <div class="field">
      <label class="label">Label</label>
      <div class="control">
        {{ message.label }}
      </div>
    </div>
    <div class="field">
      <label class="label">Confirmed</label>
      <div class="control">
        {{ message.confirmed ? 'yes' : 'no' }}
      </div>
    </div>
    <div class="field">
      <label class="label">Raw</label>
      <div class="control raw-msg">
        {{ message.raw }}
      </div>
    </div>
    <div class="field">
      <label class="label">Data</label>
      <vue-json-pretty :data="message.data" ></vue-json-pretty>
      <b-button size="is-small" class="bd-copy" v-on:click="doCopy(message.data)">
        copy
      </b-button>
    </div>

    <CallbackDetail :data="message.callback" ></CallbackDetail>

  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import Message from '../models/Message';
import CallbackDetail from '../components/CallbackDetail.vue';

export default {
  name: 'message',
  components: {
    VueJsonPretty, CallbackDetail,
  },
  data() {
    return {
      message: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetch();
    }
  },
  methods: {
    async fetch() {
      const { data } = await Message.getOne(this.$route.params.id);
      this.message = data;
    },
    doCopy(message) {
      const toast = this.$toast;
      this.$copyText(JSON.stringify(message, null, 2)).then(() => {
        toast.success('Copied to clipboard');
      }, () => {
        toast.error('Can not copy');
      });
    },
  },
};
</script>

<style>
.bd-copy {
    background: 0 0;
    border: none;
    cursor: pointer;
    font-size: .625rem;
    outline: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    right: .25rem;
    top: .25rem;
}
.bd-copy:hover {
  background-color: #ffdd57;
  color: rgba(0,0,0,0.7);
}
.bd-copy:focus {
  box-shadow: none !important;
}
.field {
  position: relative;
}
.transparent-bg {
  background-color: transparent !important;
}
</style>
